'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

var DEVTOOLS = Symbol();
var redux = function redux(reducer, initial) {
  return function (set, get, api) {
    api.dispatch = function (action) {
      set(function (state) {
        return reducer(state, action);
      });

      if (api.devtools) {
        api.devtools.send(api.devtools.prefix + action.type, get());
      }

      return action;
    };

    return _extends({
      dispatch: api.dispatch
    }, initial);
  };
};
var devtools = function devtools(fn, options) {
  return function (set, get, api) {
    var extension;

    try {
      extension = window.__REDUX_DEVTOOLS_EXTENSION__ || window.top.__REDUX_DEVTOOLS_EXTENSION__;
    } catch (_unused) {}

    if (!extension) {
      if (process.env.NODE_ENV === 'development' && typeof window !== 'undefined') {
        console.warn('Please install/enable Redux devtools extension');
      }

      delete api.devtools;
      return fn(set, get, api);
    }

    var namedSet = function namedSet(state, replace, name) {
      set(state, replace);

      if (!api.dispatch && api.devtools) {
        api.devtools.send(api.devtools.prefix + (name || 'action'), get());
      }
    };

    api.setState = namedSet;
    var initialState = fn(namedSet, get, api);

    if (!api.devtools) {
      var _options;

      var savedSetState = api.setState;

      api.setState = function (state, replace) {
        var newState = api.getState();

        if (state !== newState) {
          savedSetState(state, replace);

          if (state !== newState[DEVTOOLS] && api.devtools) {
            api.devtools.send(api.devtools.prefix + 'setState', api.getState());
          }
        }
      };

      options = typeof options === 'string' ? {
        name: options
      } : options;
      var connection = api.devtools = extension.connect(_extends({}, options));
      connection.prefix = (_options = options) != null && _options.name ? options.name + " > " : '';
      connection.subscribe(function (message) {
        var _message$payload, _message$payload2;

        if (message.type === 'ACTION' && message.payload) {
          try {
            api.setState(JSON.parse(message.payload));
          } catch (e) {
            console.error('please dispatch a serializable value that JSON.parse() support\n', e);
          }
        } else if (message.type === 'DISPATCH' && message.state) {
          var jumpState = message.payload.type === 'JUMP_TO_ACTION' || message.payload.type === 'JUMP_TO_STATE';
          var newState = api.getState();
          newState[DEVTOOLS] = JSON.parse(message.state);

          if (!api.dispatch && !jumpState) {
            api.setState(newState);
          } else if (jumpState) {
            api.setState(newState[DEVTOOLS]);
          } else {
            savedSetState(newState);
          }
        } else if (message.type === 'DISPATCH' && ((_message$payload = message.payload) == null ? void 0 : _message$payload.type) === 'COMMIT') {
          connection.init(api.getState());
        } else if (message.type === 'DISPATCH' && ((_message$payload2 = message.payload) == null ? void 0 : _message$payload2.type) === 'IMPORT_STATE') {
          var _message$payload$next, _message$payload$next2;

          var actions = (_message$payload$next = message.payload.nextLiftedState) == null ? void 0 : _message$payload$next.actionsById;
          var computedStates = ((_message$payload$next2 = message.payload.nextLiftedState) == null ? void 0 : _message$payload$next2.computedStates) || [];
          computedStates.forEach(function (_ref, index) {
            var state = _ref.state;
            var action = actions[index] || 'No action found';

            if (index === 0) {
              connection.init(state);
            } else {
              savedSetState(state);
              connection.send(action, api.getState());
            }
          });
        }
      });
      connection.init(initialState);
    }

    return initialState;
  };
};
var subscribeWithSelector = function subscribeWithSelector(fn) {
  return function (set, get, api) {
    var origSubscribe = api.subscribe;

    api.subscribe = function (selector, optListener, options) {
      var listener = selector;

      if (optListener) {
        var equalityFn = (options == null ? void 0 : options.equalityFn) || Object.is;
        var currentSlice = selector(api.getState());

        listener = function listener(state) {
          var nextSlice = selector(state);

          if (!equalityFn(currentSlice, nextSlice)) {
            var previousSlice = currentSlice;
            optListener(currentSlice = nextSlice, previousSlice);
          }
        };

        if (options != null && options.fireImmediately) {
          optListener(currentSlice, currentSlice);
        }
      }

      return origSubscribe(listener);
    };

    var initialState = fn(set, get, api);
    return initialState;
  };
};
var combine = function combine(initialState, create) {
  return function (set, get, api) {
    return Object.assign({}, initialState, create(set, get, api));
  };
};

var toThenable = function toThenable(fn) {
  return function (input) {
    try {
      var result = fn(input);

      if (result instanceof Promise) {
        return result;
      }

      return {
        then: function then(onFulfilled) {
          return toThenable(onFulfilled)(result);
        },
        catch: function _catch(_onRejected) {
          return this;
        }
      };
    } catch (e) {
      return {
        then: function then(_onFulfilled) {
          return this;
        },
        catch: function _catch(onRejected) {
          return toThenable(onRejected)(e);
        }
      };
    }
  };
};

var persist = function persist(config, baseOptions) {
  return function (set, get, api) {
    var options = _extends({
      getStorage: function getStorage() {
        return localStorage;
      },
      serialize: JSON.stringify,
      deserialize: JSON.parse,
      partialize: function partialize(state) {
        return state;
      },
      version: 0,
      merge: function merge(persistedState, currentState) {
        return _extends({}, currentState, persistedState);
      }
    }, baseOptions);

    if (options.blacklist || options.whitelist) {
      console.warn("The " + (options.blacklist ? 'blacklist' : 'whitelist') + " option is deprecated and will be removed in the next version. Please use the 'partialize' option instead.");
    }

    var _hasHydrated = false;
    var onHydrateCallbacks = [];
    var storage;

    try {
      storage = options.getStorage();
    } catch (e) {}

    if (!storage) {
      return config(function () {
        console.warn("[zustand persist middleware] Unable to update item '" + options.name + "', the given storage is currently unavailable.");
        set.apply(void 0, arguments);
      }, get, api);
    } else if (!storage.removeItem) {
      console.warn("[zustand persist middleware] The given storage for item '" + options.name + "' does not contain a 'removeItem' method, which will be required in v4.");
    }

    var thenableSerialize = toThenable(options.serialize);

    var setItem = function setItem() {
      var state = options.partialize(_extends({}, get()));

      if (options.whitelist) {
        Object.keys(state).forEach(function (key) {
          var _options$whitelist;

          !((_options$whitelist = options.whitelist) != null && _options$whitelist.includes(key)) && delete state[key];
        });
      }

      if (options.blacklist) {
        options.blacklist.forEach(function (key) {
          return delete state[key];
        });
      }

      var errorInSync;
      var thenable = thenableSerialize({
        state: state,
        version: options.version
      }).then(function (serializedValue) {
        return storage.setItem(options.name, serializedValue);
      }).catch(function (e) {
        errorInSync = e;
      });

      if (errorInSync) {
        throw errorInSync;
      }

      return thenable;
    };

    var savedSetState = api.setState;

    api.setState = function (state, replace) {
      savedSetState(state, replace);
      void setItem();
    };

    var configResult = config(function () {
      set.apply(void 0, arguments);
      void setItem();
    }, get, api);
    var stateFromStorage;

    var hydrate = function hydrate() {
      if (!storage) return;
      _hasHydrated = false;
      var postRehydrationCallback = (options.onRehydrateStorage == null ? void 0 : options.onRehydrateStorage(get())) || undefined;
      return toThenable(storage.getItem.bind(storage))(options.name).then(function (storageValue) {
        if (storageValue) {
          return options.deserialize(storageValue);
        }
      }).then(function (deserializedStorageValue) {
        if (deserializedStorageValue) {
          if (typeof deserializedStorageValue.version === 'number' && deserializedStorageValue.version !== options.version) {
            if (options.migrate) {
              return options.migrate(deserializedStorageValue.state, deserializedStorageValue.version);
            }

            console.error("State loaded from storage couldn't be migrated since no migrate function was provided");
          } else {
            return deserializedStorageValue.state;
          }
        }
      }).then(function (migratedState) {
        stateFromStorage = options.merge(migratedState, configResult);
        set(stateFromStorage, true);
        return setItem();
      }).then(function () {
        postRehydrationCallback == null ? void 0 : postRehydrationCallback(stateFromStorage, undefined);
        _hasHydrated = true;
        onHydrateCallbacks.forEach(function (cb) {
          return cb(stateFromStorage);
        });
      }).catch(function (e) {
        postRehydrationCallback == null ? void 0 : postRehydrationCallback(undefined, e);
      });
    };

    api.persist = {
      setOptions: function setOptions(newOptions) {
        options = _extends({}, options, newOptions);

        if (newOptions.getStorage) {
          storage = newOptions.getStorage();
        }
      },
      clearStorage: function clearStorage() {
        var _storage;

        (_storage = storage) == null ? void 0 : _storage.removeItem == null ? void 0 : _storage.removeItem(options.name);
      },
      rehydrate: function rehydrate() {
        return hydrate();
      },
      hasHydrated: function hasHydrated() {
        return _hasHydrated;
      },
      onHydrate: function onHydrate(cb) {
        onHydrateCallbacks.push(cb);
      }
    };
    hydrate();
    return stateFromStorage || configResult;
  };
};

exports.combine = combine;
exports.devtools = devtools;
exports.persist = persist;
exports.redux = redux;
exports.subscribeWithSelector = subscribeWithSelector;
